import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { graphql, Link, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import bp from './common/breakpoints'
import HamburguerSVG from '../images/icons/menu.svg'
import CloseSVG from '../images/icons/cancel.svg'
import { WHITE_COLOR, BLACK_COLOR } from './common/color'
import CorcheteLeft from '../images/assets/corcheteLeft.inline.svg'
import CorcheteRight from '../images/assets/corcheteRight.inline.svg'
import { NavContext } from '../context/nav-context'
import { useLocation } from '@reach/router'
import { motion } from 'framer-motion'
import { MainButton } from './common/button'
import { useMediaQuery } from '@react-hook/media-query'
import { DESKTOP_DEVICE } from './common/breakpoints'
import SearchComponent from './SearchComponent';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 0px;
  width: 90%;
  height: 7vh;
  box-sizing: border-box;
  ${bp[3]} {
    display: grid;
    grid-auto-flow: column;
    padding-top: 1.25rem;
    padding: 0 0px;
    max-width: 1480px;
    background: none;
  }
`
const LeftSide = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  z-index: 3002;
  gap: 4rem;
  height: 100%;
`
const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  height: 100%;
`
const NavContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`
const ShorcutButton = styled(Link)`
  display: none;
  margin-left: auto;
  font-family: 'Source Code Pro' !important;
  letter-spacing: 0.115em;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.opacity || 0.4};
  color: ${(props) => props.color || WHITE_COLOR};
  padding: 8px;
  transition: 100ms ease-out;
  gap: 1rem;
  &:hover {
    opacity: 1;
    color: ${(props) => props.color || WHITE_COLOR};
  }
  ${bp[2]} {
    display: flex;
  }
  @media (max-width: 1240px) {
    color: ${BLACK_COLOR};
  }
`
const NavButton = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
const DisplayMenuButton = styled.div`
  color: ${WHITE_COLOR};
  background: ${BLACK_COLOR};
  border-radius: 4px;
  z-index: 999;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${bp[3]} {
    display: flex;
  }
`
const SubMenuContainer = styled(motion.div)`
  z-index: 1;
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  background: ${BLACK_COLOR};
  box-shadow: 0px 4px 30px rgba(15, 21, 27, 0.16);
  ${bp[3]} {
    height: 11vh;
    max-height: 108px;
  }
  ${bp[4]} {
    height: 11vh;
    max-height: 128px;
  }
`
const MenuListContainer = styled(motion.div)`
  border-top: 1px solid ${WHITE_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 90%;
  max-width: 1480px;
  height: 90%;
  max-height: 40px;
  margin-top: 6vh;
  padding-top: 10vh;
  ${bp[3]} {
    flex-direction: row;
    padding-top: 0vh;
  }
`
const MenuLink = styled(Link)`
  font-family: 'Source Code Pro' !important;
  text-transform: uppercase;
  letter-spacing: 0.115em;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.4;
  color: ${(props) => props.color || WHITE_COLOR};
  transition: 100ms ease-out;
  &:hover {
    opacity: 1;
    color: ${WHITE_COLOR};
  }
`

const MenuList = [
  {
    title: 'Services',
    to: '/#services',
  },
  {
    title: 'About',
    to: '/about',
  },
  {
    title: 'Partners',
    to: '/partners',
  },
  // {
  //   title: 'Teams',
  //   to: '/teams',
  // },
  {
    title: 'Solutions',
    to: '/solutions',
  },
  {
    title: 'CASE STUDIES',
    to: '/case-studies',
  },
  {
    title: 'Developers blog',
    to: '/blog',
  },
  {
    title: 'White papers',
    to: '/white-paper',
  },
]

export const SubMenuNav = () => {
  const { setMenuOpen } = useContext(NavContext)
  return (
    <SubMenuContainer
      transition={{ type: 'tween', ease: 'easeOut', duration: 0.2 }}
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
      <MenuListContainer>
        {MenuList.map((item, index) => (
          <MenuLink
            to={item.to}
            color={`${WHITE_COLOR}`}
            onClick={() => setMenuOpen((prev) => !prev)}
          >
            {item.title}
          </MenuLink>
        ))}
      </MenuListContainer>
    </SubMenuContainer>
  )
}

export const PrimeMenuNav = () => {
  const { toggleState, toggleTab, isMenuOpen, setMenuOpen } = useContext(
    NavContext
  )
  const { logo, logoBlack } = useStaticQuery(query)
  const location = useLocation()
  const matchMobile = useMediaQuery(`(min-width: ${DESKTOP_DEVICE}px)`)
  return (
    <>
      <Header>
        <LeftSide>
          <Link to={'/'}>
            {matchMobile === true ? (<GatsbyImage fixed={logoBlack.fixed} />) : (isMenuOpen === false ? (<GatsbyImage fixed={logo.fixed} />) : (<GatsbyImage fixed={logoBlack.fixed} />))}
          </Link>
          <NavContainer>
            {Nav.map((item, index) => (
              <ShorcutButton
                opacity={toggleState === index ? 1 : 0.4}
                color={`${WHITE_COLOR}`}
                toggleState={toggleState}
                key={item.index}
                to={item.to}
                onClick={() => toggleTab(index)}
              >
                <NavButton>
                  {toggleState === index ? <CorcheteLeft /> : null}
                  {item.title}
                  {toggleState === index ? <CorcheteRight /> : null}
                </NavButton>
              </ShorcutButton>
            ))}
          </NavContainer>
        </LeftSide>
        <RightSide>
        <SearchComponent> </SearchComponent>
          {location.pathname === '/' ? null : (
            <MainButton
              to={'/#contact'}
              icon={'coffee'}
              style={!matchMobile ? { display: 'none' } : null}
            >
              Contact Us
            </MainButton>
            
          )}
          {matchMobile === true ? (null) :
            <DisplayMenuButton onClick={() => setMenuOpen((prev) => !prev)}>
              {(!isMenuOpen ? <HamburguerSVG /> : <CloseSVG />)}
            </DisplayMenuButton>
          }
        </RightSide>
      </Header>
    </>
  )
}

export const MobileMenuNav = () => {
  const { isMenuOpen } = useContext(
    NavContext
  )
  return (
    <>
      {isMenuOpen === true ? <SubMenuNav /> : null}
      <PrimeMenuNav />
    </>
  )
}

export const DesktopMenuNav = () => {
  return (
    <>
      <SubMenuNav />
      <PrimeMenuNav />
    </>
  )
}

const Nav = [
  {
    title: 'HOME',
    key: 'home',
    to: '/',
  },
  {
    title: 'CAREER OPPORTUNITIES',
    to: '/careers-opportunity',
    key: 'case-studies',
  },
  {
    title: 'COMMUNITY',
    to: '/community',
    key: 'community',
  },
]

const HeaderNav = () => {
  const matchMobile = useMediaQuery(`(min-width: ${DESKTOP_DEVICE}px)`)
  return (
    <>
      {matchMobile === true ?
        <DesktopMenuNav />
        :
        <MobileMenuNav />
      }
    </>
  )
}

const query = graphql`
  query {
    logo: imageSharp(fixed: { originalName: { eq: "alio-logo-white.png" } }) {
      fixed(height: 24) {
        ...GatsbyImageSharpFixed
      }
    }
    logoBlack: imageSharp(
      fixed: { originalName: { eq: "alio-logo-black.png" } }
    ) {
      fixed(height: 24) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export default HeaderNav
