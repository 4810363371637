import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import dataJson from '../content/alioit_content.json';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: #f1f1f1;
  padding: 3px 8px;
  border-radius: 5px;
  max-height: 5vh;
  @media (max-width: 768px) {
    padding: 2px 3px;
    margin-right: 5px;
  }
`;

const Input = styled.input`
  border: none;
  background: none;
  outline: none;
  padding: 3px;
  flex: 1;
  @media (max-width: 768px) {
    padding: 1px;
    font-size: 0.8rem;
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 3px;
  @media (max-width: 768px) {
    padding: 1px;
  }
`;

const Results = styled.div`
  position: absolute;
  margin-top: 10px;
  background: white;
  max-width: 800px;
  top: 55px;
  right: 0;
  width: 100%;
  z-index: 10;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 768px) {
    top: 45px;
  }
`;

const Item = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  @media (max-width: 768px) {
    padding: 5px 0;
  }
`;

const Container = styled.div`
  z-index: 999;
`;

const Text = styled.p`
  margin: 5px 0;
  color: #666;
  padding: 10px;
  @media (max-width: 768px) {
    padding: 5px;
    font-size: 0.8rem;
  }
`;

const Link = styled.a`
  color: #1a73e8;
  text-decoration: none;
  padding: 10px;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    padding: 5px;
    font-size: 0.8rem;
  }
`;

const SearchComponent = () => {
  const [data, setData] = useState(dataJson);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    const filteredResults = data.filter(item => 
      query ? item.text.toLowerCase().includes(query.toLowerCase()) : false
    ).slice(0, 5);  // limit results to 5
    setResults(filteredResults);
  }, [data, query]);

  return (
    <Container>
      <Wrapper>
        <Input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search..."
        />
        <Button>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </Wrapper>
      <Results>
        {results.map((result, index) => (
          <Item key={index}>
            <Text>{result.text.slice(0, 100)}</Text> {/* limit text to 100 characters */}
            {result.link && (
              <Link href={result.link} target="_blank" rel="noopener noreferrer">
                {result.link}
              </Link>
            )}
          </Item>
        ))}
      </Results>
    </Container>
  );
};

export default SearchComponent;
